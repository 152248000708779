import React from "react";
import { withI18next } from "lib/withI18next";
import { page } from "components/page";
import gql from "graphql-tag";
import GraphqlFragment from "lib/GraphqlFragment";
import DataList from "components/list/DataList";
import NewsRemoteDisplayComp from "components/resource/NewsRemoteDisplayComp";
import NewsLayout from "components/NewsLayout";

const getNewsList = gql`
  query getNewsList($searchForm: NewsForm){
    getNewsList(Input: $searchForm){
      ${GraphqlFragment.defaultLayoutReturn}
    }
  }
  ${GraphqlFragment.defaultLayoutFragment} 
`;

@withI18next(["common"])
@page
class RemoteNewsListComp extends React.Component {
  constructor(props) {
    super(props);
    //let state = this.getQueryState(props);
    //this.state = state;
    this.state = {};
  }

  render() {
    let { searchForm, newsType } = this.props;
    //console.log("newsType="+search.newsType);

    console.log("===RemoteNewsListComp start===");
    console.log(searchForm);
    console.log("this.props.newsType=" + newsType);
    console.log("===RemoteNewsListComp end===");

    return (
      <DataList
        query={getNewsList}
        variables={{
          searchForm: searchForm,
        }}
        fetchPolicy={"network-only"}
        displayComp={NewsRemoteDisplayComp}
        pagination={"FixedPagination"}
        customComponentLayout={NewsLayout}
      />
    );
  }
}

export default RemoteNewsListComp;
